.shopOptions {
    min-height:35em;
    padding-top:5em;
    padding-bottom:1em;
}

.shopOptionWrapper {
    margin-left:auto;
}

.product {
    padding-left: 1em;
    padding-right: 1em;
    max-height: 32em;
    margin-bottom: 0.75em;
}

.productCard {
    width: 18em!important;
    height: 30em!important;
    margin-left: auto;
    margin-right: auto;
    border: 0!important;
}

.productTitle {
    text-align: left;
}

.productDescription {
    text-align: left;
}

.productActionButton {
    text-align: right !important;
    margin-left: 0.5em;
    float:right;
    align-items:flex-end;
}

.shopImage {
    width:100%;
    max-height: 18em;
}