.games-landing {
    max-width: 1168px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;
}
.games-landing ul {
    list-style:none;
}
.game-link-subtitle {
    color:grey;
    /* font-style: italic; */
}
.bi {
    margin-right: 6px;
}
.game-share-button {
    margin-bottom: 6px;
    margin-left: 6px !important;
}
.sellsheet-img {
    width: 100%;
    height: auto;
}
.game-name-header-link {
    text-align: left;
}
.back-to-top {
    margin-bottom: 2em;
}