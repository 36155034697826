.footer {
    height:4em;
    padding:6px;
}
.footer.light {
    background-color: #fafafa;
}

.footer.dark {
    background-color: #141414;
}
.footer>p {
    text-align: center;
}