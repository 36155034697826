.cart-landing {
    height:100%;
}

.cart-splash { 
    height: 100%;
}

.checkout-splash-row {
    height: 100%;
}

.first-splash {
    padding-top: 5em;
}

.cart {
    padding-top:4em;
    margin-left:auto;
    margin-right:auto;
    min-height: 46em;
}

.cart-header {
    text-align: left;
}

.checkout-button{
    margin-left:auto;
    margin-top:1em;
}