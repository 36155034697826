.project {
    width: 320px;
    height: 200px;
    background-color: #dfdfdf;
    border-radius: 6px;
    border-color: #afafaf;
    border-width: 1px;
    border-style: solid;
    padding: 1em;
    color: black !important;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}
.project:hover {
    background-color: #efefef
}
.projects-header {
    text-align: center;
}
.portrait {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 1em;
    margin-right:auto;
    text-align: left;
}
.explanation-column {
    max-width: 725px !important;
}
.go-to-games {
    margin-left: 0px;
}