.topbar {
    height:4em;
}

.topbar.light {
    background-color: #fafafa;
}

.topbar.dark {
    background-color: #141414!important;
}

.topbar-ul {
    list-style: none;
    padding-left:0;
}

.topbar-li {
    float: right;
    text-align: right;
    margin-right:1em;
}
a {
    text-decoration: none!important;
    margin-left:1em;
}

.faked-link {
    text-decoration: none;
}
.nav-link.light,.nav-link:hover.light {
    color: rgba(0, 0, 0, 0.55);
}

.nav-link.dark,.nav-link:hover.dark {
    color: rgba(255, 255, 255, 0.55);
}

span.light>a,a.light {
    color: black;
}

span.dark>a,a.dark {
    color: white;
}