.company-info {
    min-height:10em;
    padding-top: 2%;
    padding-bottom: 2%;
    border-top-width: 2px;
    border-top-style:solid;
}

.company-info.light {
    border-top-color: #fafafa;
}

.company-info.dark {
    border-top-color: #151515;
}

.link-list-ul {
    list-style: none;
    padding-left:0;
}

.link-list-li {
    text-align: left;
}
.link-list-li>a {
    text-decoration: none;
}
a.light {
    color: black;
}
a.dark {
    color: white;
}


.link-list-name-header {
    text-align: left;
}

.company-info-name  {
    display:flex;
    align-items: center;
    justify-content: center;
}

.landing {
    height: 100%;
}

.content {
    padding-top:4em;
    margin-left:auto;
    margin-right:auto;
    min-height: 46em;
}

.content>* {
    text-align: left;
}