.cart-item {
    background-color: #fafafa;
    border-radius: 5px;
    margin-bottom: 1em;
    padding-top:0.25em;
    padding-bottom: 0.4em;
}

.cart-items {
    padding-left: 2em;
}

.name {
    text-align: left;
}
.price {
    text-align: right;
}

.quantity {
    margin-bottom:0;
    text-align: right;
}

.quantity-button-group {
    float:right;
}